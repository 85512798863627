/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './css/game.css';
import '../components/css/table.css';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import { auth, db } from '../config/firebase';
import { updateDoc, arrayUnion, collection, getDocs, where, query, addDoc, doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import POScontent from '../components/posContent';

const SinglePatti = () => {
    const { id } = useParams();
    const [userDataState, setUserDataState] = useState(null);
    const [gameDigit, setGameDigit] = useState('');
    const [amount, setAmount] = useState('');
    const [entries, setEntries] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const [gameName, setGameName] = useState('');
    const [printData, setPrintData] = useState(null);

    const numberwithValue = [
        { number: '1', value: 1, color: 'red' },
        { number: '2', value: 2, color: 'green' },
        { number: '3', value: 3, color: 'purple' },
        { number: '4', value: 4, color: 'blue' },
        { number: '5', value: 5, color: 'red' },
        { number: '6', value: 6, color: 'green' },
        { number: '7', value: 7, color: 'purple' },
        { number: '8', value: 8, color: 'blue' },
        { number: '9', value: 9, color: 'red' },
        { number: '0', value: 0, color: 'green' }
    ];

    useEffect(() => {
        const fetchUserProfile = async () => {
            console.log(new Date().toLocaleTimeString());
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('User not logged in');
                }
                const q = query(collection(db, 'userProfile'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    throw new Error('User profile not found');
                }

                const userProfileData = querySnapshot.docs[0].data();
                setWalletBalance(userProfileData.wallet || 0);
                setUserDataState(userProfileData);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        const fetchGameName = async () => {
            try {
                const currentDate = new Date();
                const day = currentDate.getDate().toString().padStart(2, '0');
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const year = currentDate.getFullYear();
                const dateString = `${day}-${month}-${year}`;

                const docRef = doc(db, dateString, id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const gameData = docSnap.data();
                    setGameName(gameData.gameName);
                } else {
                    throw new Error('Game not found');
                }
            } catch (error) {
                console.error('Error fetching game:', error);
            }
        };

        if (auth.currentUser) {
            fetchUserProfile();
        }
        fetchGameName();
    }, [id]);

    const handleNumberClick = (value) => {
        setGameDigit(value);
    };

    const handleAddClick = () => {
        const parsedWalletBalance = parseFloat(walletBalance);
        const parsedAmount = parseFloat(amount);

        console.log(parsedWalletBalance);

        if (gameDigit !== '' && amount !== '') {
            if (parsedAmount < 10) {
                setError('Minimum amount must be 10');
                return;
            }
            if (parsedAmount > parsedWalletBalance) {
                console.log(parsedAmount);
                setError('Insufficient wallet balance');
                return;
            }
            if (parsedAmount > 10000) {
                setError('Amount must not exceed 10000');
                return;
            }
            setEntries([...entries, { gameDigit, amount: parsedAmount }]);
            setGameDigit('');
            setAmount('');
            setError('');
        }
    };


    const handleDelete = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handlePlayGame = async (print = false) => {
        setLoading(true);

        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('User not logged in');
            }

            const q = query(collection(db, 'userProfile'), where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                throw new Error('User profile not found');
            }
            const userProfileDoc = querySnapshot.docs[0];
            const userProfileRef = userProfileDoc.ref;
            const userProfileData = userProfileDoc.data();

            const totalBetAmount = entries.reduce((sum, entry) => sum + entry.amount, 0);
            if (totalBetAmount > userProfileData.wallet) {
                setError('Insufficient wallet balance');
                setLoading(false);
                return;
            }
            const gameID = id;
            const date = new Date();
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            const betLogs = entries.map(entry => ({
                gameID: id,
                date: formatDate(new Date()),
                time: formattedTime,
                amount: entry.amount,
                number: entry.gameDigit,
                status: "",
                gameName: gameName,
                betID: "" // Placeholder for betID
            }));

            const betsCollectionRef = collection(db, 'bets');
            const betIDs = [];
            const superadminEmail = userProfileData?.superadmin ? userProfileData?.superadmin : 'No'

            for (const betLog of betLogs) {
                // Add the bet document and get its reference
                const betDocRef = await addDoc(betsCollectionRef, {
                    gameName: gameName,
                    uid: user.uid,
                    masterEmail: userDataState.masterEmail || '',
                    adminEmail: userDataState.adminEmail || '',
                 
                    gameID: betLog.gameID,
                    date: betLog.date,
                    time: betLog.time,
                    amount: betLog.amount,
                    number: betLog.number,
                    status: betLog.status,
                    user: user.email,
                    name: user.displayName,
                    phoneNumber: userProfileData.phoneNumber,
                    userProfileID: userProfileDoc.id,
                    superadmin: superadminEmail

                });

                // Get the document ID
                const betID = betDocRef.id;
                betIDs.push(betID);

                // Log the bet ID and document path
                console.log(`Bet ID created: ${betID}`);
                console.log(`Document path: ${betDocRef.path}`);

                // Update the bet document with its ID
                await updateDoc(betDocRef, { betID });

                // Update betLog with betID
                betLog.betID = betID;
            }

            await updateDoc(userProfileRef, {
                betLog: arrayUnion(...betLogs),
                wallet: userProfileData.wallet - totalBetAmount
            });

            setEntries([]);
            setWalletBalance(userProfileData.wallet - totalBetAmount);
            setError('');

            if (print) {
                setPrintData({
                    gameName,
                    gameTime: new Date().toLocaleTimeString(),
                    gameDate: formatDate(new Date()),
                    gameAmountandDigit: entries.map(entry => ({
                        amount: entry.amount.toString(),
                        digit: entry.gameDigit
                    })),
                    gameType: 'Single'
                });
            }

            console.log("Bet added successfully and new documents created");
            alert('Bet Added Successfully')
        } catch (error) {
            console.error("Error adding bet:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleQuickAmountClick = (value) => {
        setAmount(Number(value));
    };


    const totalAmount = entries.reduce((sum, entry) => sum + entry.amount, 0);

    return (
        <div>
            <NavbarTop />
            <div className="single-patti-container">
                <div className="single-patti-content hidden-print">
                    <div className="single-patti-heading">
                        <h1>Single</h1>
                        <p className="gameId">Game Id : {id}</p>
                    </div>
                    <div className="game-content-place">
                        <div className="gameInputPlace">
                            <input
                                type="number"
                                id='gameDigit'
                                placeholder='Enter Digit'
                                value={gameDigit}
                                onChange={(e) => setGameDigit(e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder='Enter Amount'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <button onClick={handleAddClick}>Add</button>
                            {error && <p className="error-message">{error}</p>}
                        </div>

                        <div className="game-number-buttons">
                            {numberwithValue.map((number, index) => (
                                <button
                                    key={index}
                                    className='game-number-button'
                                    style={{ backgroundColor: `${number.color}` }}
                                    onClick={() => handleNumberClick(number.value)}
                                >
                                    {number.number}
                                </button>
                            ))}
                        </div>
                        <p className="quickAmounts">
                            Quick Amounts
                        </p>
                        <div className="quick-amount-buttons">
                            {[10, 20, 50, 100, 200, 500, 1000].map((value, index) => (
                                <button
                                    key={index}
                                    className='quick-amount-button'
                                    onClick={() => handleQuickAmountClick(value)}
                                >
                                    {value}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="table-wrapper hidden-print">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries.map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.gameDigit}</td>
                                    <td>{entry.amount}</td>
                                    <td><button onClick={() => handleDelete(index)}>Delete</button></td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Amount:</td>
                                <td style={{ fontWeight: 'bold' }}>{totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {entries.length > 0 && (
                    <div className="game-button-group hidden-print">
                        <button
                            className='play-game-button'
                            onClick={() => handlePlayGame(true)} // Place Bet and Print
                            disabled={loading}
                        >
                            Place Bet and Print
                        </button>
                        <button
                            className='play-game-button'
                            onClick={() => handlePlayGame()} // Place Bet Only
                            disabled={loading}
                        >
                            Place Bet Only
                        </button>
                    </div>
                )}
                <div className="single-patti-print">
                    {printData && <POScontent gameData={printData} />}
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
};

export default SinglePatti;
