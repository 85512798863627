import React, { useState, useEffect } from 'react';
import './css/game.css';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import { auth, db } from '../config/firebase';
import { doc, updateDoc, getDoc, arrayUnion, collection, getDocs, where, query, addDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import POScontent from '../components/posContent';


const TriplePatti = () => {

    const { id } = useParams()
    const [printData, setPrintData] = useState({});
    const [userDataState, setUserDataState] = useState()
    const [selectedNumbers, setSelectedNumbers] = useState(['-', '-', '-']);
    const [amount, setAmount] = useState('');
    const [entries, setEntries] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0); // State to store wallet balance
    const [gameName, setGameName] = useState('');
    const [gameTiming, setGameTiming] = useState(null)
    const [showPattiList, setShowPattiList] = useState(false)
    const [showPrint, setShowPrint] = useState(false)

    const pattiList = [
        { number: '000' },
        { number: '100' },
        { number: '200' },
        { number: '300' },
        { number: '400' },
        { number: '500' },
        { number: '600' },
        { number: '700' },
        { number: '800' },
        { number: '900' },
        { number: '127' },
        { number: '678' },
        { number: '345' },
        { number: '120' },
        { number: '789' },
        { number: '456' },
        { number: '123' },
        { number: '890' },
        { number: '567' },
        { number: '234' },
        { number: '190' },
        { number: '777' },
        { number: '444' },
        { number: '111' },
        { number: '888' },
        { number: '555' },
        { number: '222' },
        { number: '999' },
        { number: '666' },
        { number: '333' },
        { number: '280' },
        { number: '560' },
        { number: '570' },
        { number: '580' },
        { number: '590' },
        { number: '140' },
        { number: '150' },
        { number: '160' },
        { number: '170' },
        { number: '180' },
        { number: '370' },
        { number: '470' },
        { number: '480' },
        { number: '490' },
        { number: '130' },
        { number: '230' },
        { number: '330' },
        { number: '340' },
        { number: '350' },
        { number: '360' },
        { number: '460' },
        { number: '380' },
        { number: '390' },
        { number: '670' },
        { number: '680' },
        { number: '690' },
        { number: '240' },
        { number: '250' },
        { number: '260' },
        { number: '270' },
        { number: '550' },
        { number: '290' },
        { number: '660' },
        { number: '238' },
        { number: '248' },
        { number: '258' },
        { number: '268' },
        { number: '278' },
        { number: '288' },
        { number: '450' },
        { number: '235' },
        { number: '119' },
        { number: '129' },
        { number: '139' },
        { number: '149' },
        { number: '159' },
        { number: '169' },
        { number: '179' },
        { number: '189' },
        { number: '199' },
        { number: '118' },
        { number: '137' },
        { number: '237' },
        { number: '337' },
        { number: '347' },
        { number: '357' },
        { number: '367' },
        { number: '377' },
        { number: '116' },
        { number: '117' },
        { number: '578' },
        { number: '236' },
        { number: '336' },
        { number: '157' },
        { number: '158' },
        { number: '799' },
        { number: '448' },
        { number: '467' },
        { number: '233' },
        { number: '469' },
        { number: '145' },
        { number: '146' },
        { number: '246' },
        { number: '346' },
        { number: '446' },
        { number: '267' },
        { number: '899' },
        { number: '115' },
        { number: '459' },
        { number: '126' },
        { number: '479' },
        { number: '669' },
        { number: '679' },
        { number: '689' },
        { number: '699' },
        { number: '780' },
        { number: '178' },
        { number: '124' },
        { number: '125' },
        { number: '667' },
        { number: '668' },
        { number: '579' },
        { number: '255' },
        { number: '355' },
        { number: '455' },
        { number: '447' },
        { number: '790' },
        { number: '223' },
        { number: '224' },
        { number: '478' },
        { number: '299' },
        { number: '399' },
        { number: '147' },
        { number: '247' },
        { number: '266' },
        { number: '366' },
        { number: '466' },
        { number: '566' },
        { number: '477' },
        { number: '135' },
        { number: '334' },
        { number: '588' },
        { number: '228' },
        { number: '256' },
        { number: '112' },
        { number: '113' },
        { number: '358' },
        { number: '557' },
        { number: '990' },
        { number: '225' },
        { number: '488' },
        { number: '489' },
        { number: '499' },
        { number: '166' },
        { number: '356' },
        { number: '122' },
        { number: '880' },
        { number: '368' },
        { number: '134' },
        { number: '144' },
        { number: '389' },
        { number: '245' },
        { number: '688' },
        { number: '599' },
        { number: '239' },
        { number: '177' },
        { number: '114' },
        { number: '359' },
        { number: '558' },
        { number: '379' },
        { number: '226' },
        { number: '155' },
        { number: '778' },
        { number: '148' },
        { number: '338' },
        { number: '249' },
        { number: '556' },
        { number: '449' },
        { number: '369' },
        { number: '559' },
        { number: '569' },
        { number: '227' },
        { number: '138' },
        { number: '788' },
        { number: '257' },
        { number: '339' },
        { number: '259' },
        { number: '269' },
        { number: '378' },
        { number: '289' },
        { number: '677' },
        { number: '344' },
        { number: '156' },
        { number: '445' },
        { number: '220' },
        { number: '889' },
        { number: '349' },
        { number: '133' },
        { number: '440' },
        { number: '388' },
        { number: '136' },
        { number: '335' },
        { number: '110' },
        { number: '229' },
        { number: '770' },
        { number: '348' },
        { number: '457' },
        { number: '188' },
        { number: '279' },
        { number: '577' },
        { number: '244' },
        { number: '128' },
        { number: '589' },
        { number: '779' },
        { number: '167' },
        { number: '168' },
        { number: '277' },
        { number: '458' },
        { number: '468' },
        { number: '568' },
    ]

    const numberwithValue = [
        { number: '1', value: 1, color: 'red' },
        { number: '2', value: 2, color: 'green' },
        { number: '3', value: 3, color: 'purple' },
        { number: '4', value: 4, color: 'blue' },
        { number: '5', value: 5, color: 'red' },
        { number: '6', value: 6, color: 'green' },
        { number: '7', value: 7, color: 'purple' },
        { number: '8', value: 8, color: 'blue' },
        { number: '9', value: 9, color: 'red' },
        { number: '0', value: 0, color: 'green' }
    ];

    useEffect(() => {
        const fetchGameName = async () => {
            try {
                const currentDate = new Date();
                const day = currentDate.getDate().toString().padStart(2, '0');
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const year = currentDate.getFullYear();
                const dateString = `${day}-${month}-${year}`;

                const docRef = doc(db, dateString, id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const gameData = docSnap.data();
                    setGameName(gameData.gameName);
                    setGameTiming(gameData.gameTimeStart + ':' + gameData.gameTimeEnd)
                    console.log('Got' + gameData.gameName);
                } else {
                    throw new Error('Game not found');
                }
            } catch (error) {
                console.error('Error fetching game:', error);
            }
        };



        const fetchUserProfile = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('User not logged in');
                }
                const q = query(collection(db, 'userProfile'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    throw new Error('User profile not found');
                }

                const userProfileData = querySnapshot.docs[0].data();
                setUserDataState(userProfileData)
                setWalletBalance(userProfileData.wallet || 0);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        if (auth.currentUser) {
            fetchUserProfile();
            fetchGameName();

        }
    }, []);
    const handleQuickAmountClick = (value) => {
        setAmount(value.toString());
    };
    const handleNumberClick = (value) => {
        if (selectedNumbers.length < 3) {
            setSelectedNumbers([...selectedNumbers, value]);
        } else {
            const newSelectedNumbers = [...selectedNumbers];
            newSelectedNumbers.push(value);
            newSelectedNumbers.shift();
            setSelectedNumbers(newSelectedNumbers);
        }
    };

    const handleAddClick = () => {
        if (amount !== '') {
            if (amount < 10) {
                setError('Minimum amount must be 10');
                return;
            }
            if (selectedNumbers.includes('-')) {
                setError('Please select exactly three digits');
                return;
            }
            const selectedNumber = selectedNumbers.join('');
            const isValidNumber = pattiList.some(patti => patti.number === selectedNumber);

            if (!isValidNumber) {
                setError('Selected number is not valid. Please choose another number.');
                return;
            }

            if (parseFloat(amount) > 10000) {
                setError('Amount must not exceed 10000');
                return;
            }

            setEntries([...entries, { numbers: selectedNumber, amount: parseFloat(amount) }]);
            setSelectedNumbers(['-', '-', '-']);
            setAmount('');
            setError('');
        }
    };


    const handleDelete = (index) => {
        const newEntries = entries.filter((_, i) => i !== index);
        setEntries(newEntries);
        console.log(walletBalance);
    };


    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    const handlePlayGame = async (print = false) => {
        setLoading(true);

        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('User not logged in');
            }

            const q = query(collection(db, 'userProfile'), where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                throw new Error('User profile not found');
            }

            const userProfileDoc = querySnapshot.docs[0];
            const userProfileRef = userProfileDoc.ref;
            const userProfileData = userProfileDoc.data();

            const totalBetAmount = entries.reduce((sum, entry) => sum + entry.amount, 0);
            if (totalBetAmount > userProfileData.wallet) {
                setError('Insufficient wallet balance');
                setLoading(false);
                return;
            }

            const gameID = id;
            const date = new Date();
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();

            const formattedDate = `${day}/${month}/${year}`;
            const formattedTime = date.toLocaleTimeString();

            // Example output:
            // formattedDate: "03/07/2024" (if today is July 3, 2024)
            // formattedTime: "12:30:45 PM" (example time format)

            console.log(formattedDate);

            const betLogs = entries.map(entry => ({
                gameID,
                date: formattedDate,
                time: formattedTime,
                amount: entry.amount,
                status: "",
                gameName: gameName,
                number: entry.numbers
            }));

            const updatedBetLogs = [];
            const superadminEmail = userProfileData?.superadmin ? userProfileData?.superadmin : 'No'
            const betsCollectionRef = collection(db, 'bets');
            for (const betLog of betLogs) {
                const betData = {
                    gameName: gameName,
                    uid: user.uid,
                    gameID: betLog.gameID,
                    date: betLog.date,
                    time: betLog.time,
                    amount: betLog.amount,
                    number: betLog.number,
                    status: betLog.status,
                    user: user.email,
                    name: user.displayName,
                    gameTiming: gameTiming,
                    phoneNumber: userProfileData.phoneNumber,
                    userProfileID: userProfileDoc.id, // Document ID of user's profile
                    superadmin: superadminEmail,
                    masterEmail: userProfileData.masterEmail || '',
                    adminEmail: userProfileData.adminEmail || ''
                };
                // console.log(betData);
                // if (userDataState.masterEmail) {
                //     betData.masterEmail = userDataState.masterEmail;
                // }

                // if (userDataState.adminEmail) {
                //     betData.adminEmail = userDataState.adminEmail;
                // }

                // Add the bet document and get its ID
                const betDocRef = await addDoc(betsCollectionRef, betData);
                const betID = betDocRef.id;

                // Update the bet document with its ID
                await updateDoc(betDocRef, { betID });

                // Add betID to betLog
                updatedBetLogs.push({ ...betLog, betID });
            }

            await updateDoc(userProfileRef, {
                betLog: arrayUnion(...updatedBetLogs),
                wallet: userProfileData.wallet - totalBetAmount
            });

            setEntries([]);
            setWalletBalance(userProfileData.wallet - totalBetAmount);
            setError('');

            // Print logic
            if (print) {
                setShowPrint(true);
                setPrintData({
                    gameName,
                    gameTime: formattedTime,
                    gameDate: formattedDate,
                    gameAmountandDigit: entries.map(entry => ({
                        amount: entry.amount.toString(),
                        digit: entry.numbers
                    })),
                    gameType: 'Triple'
                });
            }

            console.log("Bet added successfully");
            alert('Bet Added Successfully')


        } catch (error) {
            console.error("Error adding bet:", error);
        } finally {
            setLoading(false);
        }
    };




    const togglePattiList = () => {
        setShowPattiList(!showPattiList);
    };
    const totalAmount = entries.reduce((sum, entry) => sum + entry.amount, 0);




    return (
        <div>
            <NavbarTop />
            <div className="single-patti-container ">
                <div className="single-patti-content hidden-print">
                    <div className="single-patti-heading">
                        <h1>Patti</h1>
                        <p className="gameId">Game Id : {id}</p>
                    </div>
                    <div className="game-content-plce">
                        <div className="gameInputPlace triple">
                            <div className="gameInputTripleSpan">
                                {selectedNumbers.map((num, index) => (
                                    <p key={index} className="gameInputTripleSpan-child">{num}</p>
                                ))}
                            </div>
                            <input
                                type="number"
                                placeholder='Enter Amount'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <button onClick={handleAddClick}>Add</button>
                            {error && <p className="error-message">{error}</p>}
                        </div>

                        <p className="quickAmounts">
                            Quick Amounts
                        </p>
                        <div className="quick-amount-buttons">
                            {[10, 20, 50, 100, 1000, 2000,].map((value, index) => (
                                <button
                                    key={index}
                                    className='quick-amount-button'
                                    onClick={() => handleQuickAmountClick(value)}
                                >
                                    {value}
                                </button>
                            ))}
                        </div>

                        <div className="game-number-buttons">
                            {numberwithValue.map((number, index) => (
                                <button
                                    key={index}
                                    className='game-number-button'
                                    style={{ backgroundColor: `${number.color}` }}
                                    onClick={() => handleNumberClick(number.value)}
                                >
                                    {number.number}
                                </button>
                            ))}
                        </div>
                        <div className="pattilist-guide">
                            <button className="patti-list-button" onClick={togglePattiList}>
                                {showPattiList ? 'Hide Patti List' : 'Open Patti List'}
                            </button>
                            {showPattiList && (
                                <div className="patti-list--div-triple">
                                    {pattiList.map((patti, index) => (
                                        <div className="patti-list-button-item-div">
                                            <button
                                                key={index}
                                                className={`patti-list-button-item ${['purple', 'red', 'green', 'blue'][index % 4]}`}
                                                onClick={() => setSelectedNumbers(patti.number.split(''))}
                                            >
                                                {patti.number}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                    </div>
                </div>

                <div className="table-wrapper hidden-print">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Amount</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries.map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.numbers}</td>
                                    <td>{entry.amount}</td>
                                    <td>
                                        <button className='buttonDelete' onClick={() => handleDelete(index)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Amount:</td>
                                <td style={{ fontWeight: 'bold' }}>{totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {entries.length > 0 && (
                    <div className="game-button-group hidden-print">
                        <button
                            className='play-game-button'
                            onClick={() => handlePlayGame(true)} // Place Bet and Print
                            disabled={loading}
                        >
                            Place Bet and Print
                        </button>
                        <button
                            className='play-game-button'
                            onClick={() => handlePlayGame()} // Place Bet Only
                            disabled={loading}
                        >
                            Place Bet Only
                        </button>
                    </div>
                )}
                <div className="single-patti-print">
                    {showPrint ? <POScontent gameData={printData} /> : null}


                </div>
            </div>
            <NavbarBottom />

        </div>
    );
};

export default TriplePatti;